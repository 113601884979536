import { branchById, workingTimeByBranch } from '@src/mockup/branch'
import { instance } from '@src/configs/axios'

const branchService = {
  getBranchById: () => {
    return new Promise((resolve) => {
      resolve(branchById)
    })
  },
  getWorkingTimeByBranch: () => {
    return new Promise((resolve) => {
      resolve(workingTimeByBranch)
    })
  },
  getBranchRivals: (brandId, branchId) => {
    return instance.get(
      `/api/admin/brands/${brandId}/branches/${branchId}/rivals`,
    )
  },
}
export default branchService
