import { instance } from '@src/configs/axios'
const employeeService = {
  createEmployee: (brand, branch, data) => {
    return instance.post(
      `/api/admin/brands/${brand}/branches/${branch}/users`,
      data,
    )
  },
  getEmployeeList: (brand, branch, params) => {
    return instance.get(`/api/admin/brands/${brand}/branches/${branch}/users`, {
      params,
    })
  },
  getEmployeeById: (brand, branch, pcId) => {
    return instance.get(
      `/api/admin/brands/${brand}/branches/${branch}/users/${pcId}`,
    )
  },
  deletePc: (brand, branch, pcId) =>
    instance.delete(
      `/api/admin/brands/${brand}/branches/${branch}/users/${pcId}`,
    ),
  editEmployee: (brand, branch, pcId, params) =>
    instance.patch(
      `/api/admin/brands/${brand}/branches/${branch}/users/${pcId}`,
      params,
    ),
  createEmployeeBulk: (brand, branch, params) =>
    instance.post(`/api/admin/brands/${brand}/branches/${branch}/users/bulk`, {
      users: params,
    }),
}

export default employeeService
