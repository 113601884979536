import { instance } from '@src/configs/axios'
const hrService = {
  getEmployeeList: (brand, branch, params) => {
    return instance.get(`/api/admin/brands/${brand}/branches/${branch}/forms`, {
      params,
    })
  },
  getById: (brand, branch, Id) => {
    return instance.get(
      `/api/admin/brands/${brand}/branches/${branch}/forms/${Id}`,
    )
  },
  approveLeaveAPI: (brand, branch, overtimeId) => {
    return instance.patch(
      `/api/admin/brands/${brand}/branches/${branch}/forms/${overtimeId}/approve`,
    )
  },
  rejectLeaveAPI: (brand, branch, overtimeId) => {
    return instance.patch(
      `/api/admin/brands/${brand}/branches/${branch}/forms/${overtimeId}/reject`,
    )
  },
  approveAPI: (brand, branch, overtimeId, data) => {
    return instance.patch(
      `/api/admin/brands/${brand}/branches/${branch}/overtimes/${overtimeId}/approve`,
      data,
    )
  },
  approveBulkLeaveAPI: (brand, branch, params) => {
    return instance.patch(
      `/api/admin/brands/${brand}/branches/${branch}/forms/approve`,
      params,
    )
  },
  rejectBulkLeaveAPI: (brand, branch, params) => {
    return instance.patch(
      `/api/admin/brands/${brand}/branches/${branch}/forms/reject`,
      params,
    )
  },
  approveBulkAPI: (brand, branch, params) => {
    return instance.patch(
      `/api/admin/brands/${brand}/branches/${branch}/overtimes/approve`,
      params,
    )
  },
  rejectBulkAPI: (brand, branch, params) => {
    return instance.patch(
      `/api/admin/brands/${brand}/branches/${branch}/overtimes/reject`,
      params,
    )
  },
  rejectAPI: (brand, branch, overtimeId) => {
    return instance.patch(
      `/api/admin/brands/${brand}/branches/${branch}/overtimes/${overtimeId}/reject`,
    )
  },
  getOTList: (brand, branch, params) => {
    return instance.get(
      `/api/admin/brands/${brand}/branches/${branch}/overtimes`,
      { params },
    )
  },
  getOTById: (brand, branch, overtimeId) => {
    return instance.get(
      `/api/admin/brands/${brand}/branches/${branch}/overtimes/${overtimeId}`,
    )
  },
}

export default hrService
