import { createContext } from 'react'

const Address = createContext()

const AddressContext = ({ children }) => {
  const preprocess = function (data) {
    let lookup = []
    let words = []
    let expanded = []
    let useLookup = false
    let t

    if (data.lookup && data.words) {
      useLookup = true
      lookup = data.lookup.split('|')
      words = data.words.split('|')
      data = data.data
    }

    t = function (text) {
      function repl(m) {
        let ch = m.charCodeAt(0)
        return words[ch < 97 ? ch - 65 : 26 + ch - 97]
      }
      if (!useLookup) {
        return text
      }
      if (typeof text === 'number') {
        text = lookup[text]
      }
      return text.replace(/[A-Z]/gi, repl)
    }

    if (!data[0].length) {
      return data
    }

    data.map(function (provinces) {
      let i = 1
      if (provinces.length === 3) {
        i = 2
      }

      provinces[i].map(function (amphoes) {
        amphoes[i].map(function (districts) {
          districts[i] =
            districts[i] instanceof Array ? districts[i] : [districts[i]]
          districts[i].map(function (zipcode) {
            let entry = {
              district: t(districts[0]),
              amphoe: t(amphoes[0]),
              province: t(provinces[0]),
              zipcode: zipcode
            }
            if (i === 2) {
              entry.district_code = districts[1] || false
              entry.amphoe_code = amphoes[1] || false
              entry.province_code = provinces[1] || false
            }
            expanded.push(entry)
          })
        })
      })
    })
    return expanded
  }

  const db = preprocess(require('../database/address.json'))

  const resolveResultbyField = (type, searchStr, maxResult) => {
    searchStr = searchStr ? searchStr.toString().trim() : ''
    if (searchStr === '') {
      return []
    }
    if (!maxResult) {
      maxResult = 20
    }
    let possibles = []
    try {
      possibles = db
        .filter((item) => {
          let regex = new RegExp(searchStr, 'g')
          return (item[type] || '').toString().match(regex)
        })
        .slice(0, maxResult)
    } catch (e) {
      return []
    }
    return possibles
  }

  const searchAddressByDistrict = (searchStr, maxResult) => {
    return resolveResultbyField('district', searchStr, maxResult)
  }
  const searchAddressByAmphoe = (searchStr, maxResult) => {
    return resolveResultbyField('amphoe', searchStr, maxResult)
  }
  const searchAddressByProvince = (searchStr, maxResult) => {
    return resolveResultbyField('province', searchStr, maxResult)
  }
  const searchAddressByZipcode = (searchStr, maxResult) => {
    return resolveResultbyField('zipcode', searchStr, maxResult)
  }

  return (
    <Address.Provider
      value={{
        searchAddressByDistrict: (value) => searchAddressByDistrict(value),
        searchAddressByAmphoe: (value) => searchAddressByAmphoe(value),
        searchAddressByProvince: (value) => searchAddressByProvince(value),
        searchAddressByZipcode: (value) => searchAddressByZipcode(value)
      }}
    >
      {children}
    </Address.Provider>
  )
}

export { Address, AddressContext }
