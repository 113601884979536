export const branchById = {
  success: true,
  data: {
    id: 1,
    name: 'บิ๊กซี สาขาหลัก 1',
    tel: '023456789',
    email: 'iget@gmail.com',
    lineId: 'iGet',
    address: '87/9 ถ.ถนน ซุปเปอร์ไฮเวย์ เชียงใหม่-ลำปาง',
    zipcode: '50000',
    province: 'เชียงใหม่',
    amphoe: 'เมืองเชียงใหม่',
    district: 'วัดเกต',
    gps: '13.819411,100.866637'
  }
}

export const workingTimeByBranch = {
  success: true,
  data: [
    {
      id: 1,
      name: 'กะเช้า 1',
      startTime: new Date(),
      endTime: new Date(),
      updateDate: new Date()
    },
    {
      id: 2,
      name: 'กะเช้า 2',
      startTime: new Date(),
      endTime: new Date(),
      updateDate: new Date()
    },
    {
      id: 3,
      name: 'กะเช้า 3',
      startTime: new Date(),
      endTime: new Date(),
      updateDate: new Date()
    }
  ]
}
