import axios from 'axios'
import { userService } from '../api'
import createAuthRefreshInterceptor from 'axios-auth-refresh'

export const instance = axios.create({ baseURL: process.env.REACT_APP_API_URL })

instance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('accessToken')
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`
    }
    return config
  },
  (error) => Promise.reject(error),
)

const refreshAuth = async (failedRequest) => {
  const refreshToken = localStorage.getItem('refreshToken')
  if (refreshToken) {
    try {
      const { data } = await userService.refreshToken(refreshToken)
      localStorage.setItem('accessToken', data?.data?.accessToken)
      failedRequest.response.config.headers['Authorization'] =
        'Bearer ' + data?.data?.accessToken
      return Promise.resolve()
    } catch (error) {
      return Promise.reject()
    }
  } else {
    return Promise.reject()
  }
}

createAuthRefreshInterceptor(instance, refreshAuth, {
  pauseInstanceWhileRefreshing: true,
})
