import { stockReportById } from '../mockup/dashboard'
import { instance } from '@src/configs/axios'

const dashboardService = {
  getDashboard: (brandId, branchId) => {
    return instance.get(
      `/api/admin/brands/${brandId}/branches/${branchId}/reports/overall`,
    )
  },
  getBrandReport: (params) =>
    instance.get(`/api/admin/report/brand`, { params }),
  getSaleReport: (brandId, branchId, params) => {
    return instance.get(
      `/api/admin/brands/${brandId}/branches/${branchId}/reports/sale`,
      { params },
    )
  },
  getSaleExcelReport: (params) => {
    return instance.get(`/api/admin/exports/sale`, {
      params,
      responseType: 'blob',
    })
  },
  getAttendanceExcelReport: (params) => {
    return instance.get(`/api/admin/exports/attendance`, {
      params,
      responseType: 'blob',
    })
  },
  getSaleOverviewReport: (brandId, branchId, params) => {
    return instance.get(
      `/api/admin/brands/${brandId}/branches/${branchId}/reports/sale/overview`,
      { params },
    )
  },
  getSaleInventoryReport: (brandId, branchId, params) => {
    return instance.get(
      `/api/admin/brands/${brandId}/branches/${branchId}/reports/sale/inventory`,
      { params },
    )
  },

  getSaleReportById(brandId, branchId, inventoryId, params) {
    return instance.get(
      `/api/admin/brands/${brandId}/branches/${branchId}/reports/sale/inventory/${inventoryId}`,
      { params },
    )
  },

  getWorkTimeReport: (brandId, branchId, params) => {
    return instance.get(
      `/api/admin/brands/${brandId}/branches/${branchId}/reports/attendance`,
      { params },
    )
  },

  getStockReport: (brandId, branchId, params) => {
    return instance.get(
      `/api/admin/brands/${brandId}/branches/${branchId}/reports/inventory`,
      { params },
    )
  },

  getReconcileReport: (brandId, branchId, params) => {
    return instance.get(
      `/api/admin/brands/${brandId}/branches/${branchId}/reports/inventory/reconcile`,
      { params },
    )
  },
  getStockReportById: () => {
    return new Promise((resolve) => {
      resolve(stockReportById)
    })
  },
  getRivalReport: (brandId, branchId, brandRivalBranchId, params) => {
    return instance.get(
      `/api/admin/brands/${brandId}/branches/${branchId}/reports/rival/${brandRivalBranchId}`,
      { params },
    )
  },
}

export default dashboardService
