import { instance } from '@src/configs/axios'

const brandService = {
  //brand
  getBrands: (params) => instance.get(`api/admin/brands`, { params }),
  getAvailableBrandWithBranch: (params) => {
    return instance.get(`api/admin/brands/available`, { params })
  },
  getBrandById: (id) => instance.get(`/api/admin/brands/` + id),
  addBrand: (params) => {
    return instance.post(`/api/admin/brands/`, params)
  },
  editBrand: (id, params) => {
    return instance.patch(`/api/admin/brands/` + id, params)
  },
  deleteBrand: (id) => instance.delete(`/api/admin/brands/` + id),
  //branch
  deleteBranch: (brandId, BranchId) => {
    return instance.delete(`/api/admin/brands/${brandId}/branches/${BranchId}`)
  },
  getBranchByBrand: (id, params) =>
    instance.get(`/api/admin/brands/${id}/branches`, { params }),
  createBranchBrand: (id, params) => {
    return instance.post(`/api/admin/brands/${id}/branches`, params)
  },
  editBranchBrand: (id, data) => {
    return instance.patch(
      `/api/admin/brands/${id.brandId}/branches/${id.branchId}`,
      data,
    )
  },
  getBranchById: (brandId, branchId) => {
    return instance.get(`/api/admin/brands/${brandId}/branches/${branchId}`)
  },
  //admin
  getAdminByBrand: (brandId, params) =>
    instance.get(`/api/admin/brands/${brandId}/managers`, { params }),
  editAdminByBrand: (id, params) => {
    let setName = params.name.split(' ')
    params.firstName = setName[0]
    params.lastName = setName[1]
    return instance.patch(
      `/api/admin/brands/${id.brandId}/managers/${id.managerId}`,
      params,
    )
  },
  createAdminByBrand: (brandId, params) => {
    let setName = params.name.split(' ')
    params.firstName = setName[0]
    params.lastName = setName[1]
    return instance.post(`/api/admin/brands/${brandId}/managers`, params)
  },
  deleteManager: (brandId, managerId) => {
    return instance.delete(`/api/admin/brands/${brandId}/managers/${managerId}`)
  },
  resetPassword: (brandId, managerId, data) => {
    return instance.patch(
      `/api/admin/brands/${brandId}/managers/${managerId}/reset-password`,
      data,
    )
  },
  //rival
  getCompetitors: (brandId, params) =>
    instance.get(`/api/admin/brands/${brandId}/rivals`, { params }),
  editCompetitors: (id, params) => {
    return instance.patch(
      `/api/admin/brands/${id.brandId}/rivals/${id.rivalId}`,
      params,
    )
  },
  deleteCompetitors: (brandId, id) => {
    return instance.delete(`/api/admin/brands/${brandId}/rivals/${id}`)
  },
  createCompetitors: (brandId, params) => {
    return instance.post(`/api/admin/brands/${brandId}/rivals`, params)
  },
  //target
  getTarget: (brandId, branchId, params) =>
    instance.get(`/api/admin/brands/${brandId}/branches/${branchId}/target`, {
      params,
    }),
  editTarget: (id, params) => {
    return instance.patch(
      `/api/admin/brands/${id.brandId}/branches/${id.branchId}/target/${id.targetId}`,
      params,
    )
  },
  //shiftwork
  getWorkingTimeByBranch: (brandId, branchId, params) =>
    instance.get(
      `/api/admin/brands/${brandId}/branches/${branchId}/shiftwork`,
      { params },
    ),
  deleteShiftWork: (brandId, branchId, shiftId) => {
    return instance.delete(
      `/api/admin/brands/${brandId}/branches/${branchId}/shiftwork/${shiftId}`,
    )
  },
  editShiftWork: (id, params) => {
    return instance.patch(
      `/api/admin/brands/${id.brandId}/branches/${id.branchId}/shiftwork/${id.shiftworkId}`,
      params,
    )
  },
  createShiftWork: (id, params) => {
    return instance.post(
      `/api/admin/brands/${id.brandId}/branches/${id.branchId}/shiftwork`,
      params,
    )
  },
  // department-store
  getDepartmentsStoreByBranch: (brandId, params) => {
    return instance.get(`/api/admin/brands/${brandId}/department-stores`, {
      params,
    })
  },
  createDepartmentsStore: (brandId, params) => {
    return instance.post(
      `/api/admin/brands/${brandId}/department-stores`,
      params,
    )
  },
  updateDepartmentsStore: (brandId, params) => {
    return instance.patch(
      `/api/admin/brands/${brandId}/department-stores`,
      params,
    )
  },
  deleteDepartmentsStore: (brandId, departmentStoreId) => {
    return instance.delete(
      `/api/admin/brands/${brandId}/department-stores/${departmentStoreId}`,
    )
  },
}

export default brandService
