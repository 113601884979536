import ReactDOM from 'react-dom'
import { Suspense, lazy } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { store } from './redux/store'
import { Provider } from 'react-redux'
import { ThemeContext } from './utility/context/ThemeColors'
import { AuthentionContext } from './utility/context/Authention'
import { AddressContext } from './utility/context/Address'
import { AbilityContext } from './utility/context/Can'
import themeConfig from './configs/themeConfig'
import { Toaster } from 'react-hot-toast'
import Spinner from './@core/components/spinner/Fallback-spinner'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import buddhistEra from 'dayjs/plugin/buddhistEra'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import ability from './configs/acl/ability'

import 'dayjs/locale/th'

dayjs.extend(duration)
dayjs.extend(buddhistEra)
dayjs.extend(customParseFormat)

import './@core/components/ripple-button'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx.min'
import 'react-perfect-scrollbar/dist/css/styles.css'
import '@styles/react/libs/react-hot-toasts/react-hot-toasts.scss'
import './@core/assets/fonts/feather/iconfont.css'
import './@core/scss/core.scss'
import './assets/scss/style.scss'
import * as serviceWorker from './serviceWorker'

const LazyApp = lazy(() => import('./App'))

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <Provider store={store}>
        <Suspense fallback={<Spinner />}>
          <AbilityContext.Provider value={ability}>
            <ThemeContext>
              <AuthentionContext>
                <AddressContext>
                  <LazyApp />
                  <Toaster
                    position={themeConfig.layout.toastPosition}
                    toastOptions={{ className: 'react-hot-toast' }}
                  />
                </AddressContext>
              </AuthentionContext>
            </ThemeContext>
          </AbilityContext.Provider>
        </Suspense>
      </Provider>
    </BrowserRouter>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
