import { instance } from '@src/configs/axios'

const endpoint = `/api/admin`
const userService = {
  login: (data) => {
    return instance.post(`api/auth/login`, data)
  },
  userGetDetail: () => instance.get(`api/auth/me`),
  changePassword: (data) => instance.patch(`api/auth/me/change-password`, data),
  refreshToken: (refreshToken) =>
    instance.post(`api/auth/refresh`, {
      refreshToken,
    }),
  getUserList: (params) => instance.get(`${endpoint}/users`, { params }),
  addUser: (params) => instance.post(`${endpoint}/users`, params),
  getUserById: (id) => instance.get(`${endpoint}/users/${id}`),
  editUser: (id, data) => instance.patch(`${endpoint}/users/${id}`, data),
  deleteUser: (id) => instance.delete(`${endpoint}/users/${id}`),
  getOverview: () => instance.get(`${endpoint}/users/overview`),
}
export default userService
