import { instance } from '@src/configs/axios'

const stockService = {
  getCategories: (brandId, params) =>
    instance.get(`/api/admin/brands/${brandId}/categories`, { params }),
  createCategory: (brandId, params) => {
    return instance.post(`/api/admin/brands/${brandId}/categories`, params)
  },
  editCategory: (id, params) => {
    return instance.patch(
      `/api/admin/brands/${id.brandId}/categories/${id.categoryId}`,
      params,
    )
  },
  deleteCategory: (brandId, categoryId) => {
    return instance.delete(
      `/api/admin/brands/${brandId}/categories/${categoryId}`,
    )
  },
  //Product
  getProducts: (brandId, params) => {
    return instance.get(`/api/admin/brands/${brandId}/products`, { params })
  },
  createProduct: (brandId, data) => {
    return instance.post(`/api/admin/brands/${brandId}/products`, data)
  },
  createProductBulk: (brandId, params) => {
    return instance.post(`/api/admin/brands/${brandId}/products/bulk`, {
      products: params,
    })
  },
  deleteProduct: (brandId, productId) => {
    return instance.delete(`/api/admin/brands/${brandId}/products/${productId}`)
  },
  getProductById: (brandId, productId) => {
    return instance.get(`/api/admin/brands/${brandId}/products/${productId}`)
  },
  editProductById: (brandId, productId, params) => {
    return instance.patch(
      `/api/admin/brands/${brandId}/products/${productId}`,
      params,
    )
  },
  //inventory
  getInventory: (brandId, branch, params) => {
    return instance.get(
      `/api/admin/brands/${brandId}/branches/${branch}/inventories`,
      { params },
    )
  },
  editInventoryById: (brandId, branch, id, data) => {
    return instance.patch(
      `/api/admin/brands/${brandId}/branches/${branch}/inventories/${id}`,
      data,
    )
  },
  reconcileInventory: (brandId, branch, id, data) => {
    return instance.patch(
      `/api/admin/brands/${brandId}/branches/${branch}/inventories/${id}/reconcile`,
      data,
    )
  },

  //Document
  getDocuments: (brandId, productId, params) => {
    return instance.get(
      `/api/admin/brands/${brandId}/products/${productId}/documents`,
      { params },
    )
  },
  createDocument: (brandId, productId, data) => {
    return instance.post(
      `/api/admin/brands/${brandId}/products/${productId}/documents`,
      data,
    )
  },
  deleteDocument: (brandId, productId, documentId) => {
    return instance.delete(
      `/api/admin/brands/${brandId}/products/${productId}/documents/${documentId}`,
    )
  },
  editDocument: (brandId, productId, params) => {
    return instance.patch(
      `/api/admin/brands/${brandId}/products/${productId}/documents`,
      params,
    )
  },
}

export default stockService
