export const dashBoard = {
  success: true,
  data: {
    saleMonth: 2356825,
    saleToday: {
      total: 236467,
      product: 456,
      avg: 518.57
    },
    startWork: {
      late: 23,
      personalLeave: 26,
      awol: 2
    },
    productsSoldItem: {
      item: {
        total: 1235
      },
      list: {
        total: 935
      }
    },
    revenue: [
      {
        name: 'เดือนนี้',
        data: [
          45000, 47000, 44800, 47500, 45500, 48000, 46500, 48600, 49600, 55600,
          58600, 59600
        ]
      },
      {
        name: 'เดือนที่แล้ว',
        data: [
          46000, 48000, 45500, 46600, 44500, 46500, 45000, 47000, 48600, 49600,
          56600, 58600
        ]
      }
    ],
    overView: [
      {
        title: 'ทีวี',
        goal: 1000000,
        amount: 524580
      },
      {
        title: 'ตู้เย็น',
        goal: 1000000,
        amount: 401345
      },
      {
        title: 'เครื่องซักผ้า',
        goal: 1000000,
        amount: 313890
      },
      {
        title: 'พัดลม',
        goal: 1000000,
        amount: 271134
      },
      {
        title: 'เครื่องฟอกอากาศ',
        goal: 1000000,
        amount: 267670
      },
      {
        title: 'แอร์',
        goal: 1000000,
        amount: 257890
      }
    ],
    productBestSale: [
      {
        title: `ทีวี UHD LED ปี 2021 (50",4K,Smart)`,
        total: 524580,
        amount: 567
      },
      {
        title: 'ตู้เย็น 2 ประตู (14.1 คิว, สี Elega',
        amount: 567,
        total: 401345
      },
      {
        title: 'เครื่องซักผ้าฝาหน้า (7 kg)',
        amount: 567,
        total: 313890
      },
      {
        title: `ทีวี TU8300 UHD LED (65", 4K,`,
        amount: 567,
        total: 271134
      },
      {
        title: 'SAMSUNG ทีวี UHD LEDปี 2021',
        amount: 567,
        total: 267670
      }
    ],
    categoryBestSale: [
      {
        title: `ทีวี`,
        total: 524580,
        amount: 567
      },
      {
        title: 'ตู้เย็น',
        amount: 567,
        total: 401345
      },
      {
        title: 'เครื่องซักผ้า',
        amount: 567,
        total: 313890
      },
      {
        title: `พัดลม`,
        amount: 567,
        total: 271134
      },
      {
        title: 'เครื่องฟอกอากาศ',
        amount: 567,
        total: 267670
      }
    ],
    employee: [
      {
        title: `อาชา สมปรารถณา`,
        amount: 567,
        total: 524580,
        profile:
          'https://static.bangkokpost.com/media/content/20200619/c1_1937552_200619122619.jpg'
      },
      {
        title: 'สมคิด มีความสุข',
        amount: 567,
        total: 401345,
        profile:
          'https://static.bangkokpost.com/media/content/20200619/c1_1937552_200619122619.jpg'
      },
      {
        title: 'ธรรมรัฐ สมบูรณ์ดี',
        amount: 567,
        total: 313890,
        profile:
          'https://static.bangkokpost.com/media/content/20200619/c1_1937552_200619122619.jpg'
      },
      {
        title: `มณีจันทร์ จันทรา`,
        amount: 567,
        total: 271134,
        profile:
          'https://static.bangkokpost.com/media/content/20200619/c1_1937552_200619122619.jpg'
      },
      {
        title: 'อรณิสา มีวาสนา',
        amount: 567,
        total: 267670,
        profile:
          'https://static.bangkokpost.com/media/content/20200619/c1_1937552_200619122619.jpg'
      }
    ]
  }
}

export const brandReport = {
  success: true,
  data: [
    {
      brand: 'บริษัท เจริญมั่งคั่ง จำกัด',
      employee: 120,
      amount: 45,
      total: 2356825
    },
    {
      brand: 'บริษัท เอสเคจี อิเล็กทรอนิกส์ จำกัด',
      employee: 390,
      amount: 65,
      total: 2356825
    },
    {
      brand: 'Jlgaming',
      employee: 234,
      amount: 23,
      total: 2356825
    },
    {
      brand: 'Tamara Shop',
      employee: 123,
      amount: 45,
      total: 2356825
    },
    {
      brand: 'Rungnapha',
      employee: 89,
      amount: 23,
      total: 2356825
    },
    {
      brand: 'บริษัท เจริญมั่งคั่ง จำกัด',
      employee: 120,
      amount: 45,
      total: 2356825
    },
    {
      brand: 'บริษัท เอสเคจี อิเล็กทรอนิกส์ จำกัด',
      employee: 390,
      amount: 65,
      total: 2356825
    },
    {
      brand: 'Jlgaming',
      employee: 234,
      amount: 23,
      total: 2356825
    },
    {
      brand: 'Tamara Shop',
      employee: 123,
      amount: 45,
      total: 2356825
    },
    {
      brand: 'Rungnapha',
      employee: 89,
      amount: 23,
      total: 2356825
    },
    {
      brand: 'บริษัท เจริญมั่งคั่ง จำกัด2',
      employee: 120,
      amount: 45,
      total: 2356825
    },
    {
      brand: 'บริษัท เอสเคจี อิเล็กทรอนิกส์ จำกัด2',
      employee: 390,
      amount: 65,
      total: 2356825
    },
    {
      brand: 'Jlgaming2',
      employee: 234,
      amount: 23,
      total: 2356825
    },
    {
      brand: 'Tamara Shop2',
      employee: 123,
      amount: 45,
      total: 2356825
    },
    {
      brand: 'Rungnapha2',
      employee: 89,
      amount: 23,
      total: 2356825
    }
  ]
}

export const saleReport = {
  success: true,
  data: {
    sales: 2356825,
    product: 1235,
    employee: 23,
    productList: [
      {
        product: `ทีวี UHD LED ปี 2021 (50",4K,Smart) รุ่น UA50AU7700KXXT`,
        price: 14988,
        qty: 1,
        total: 14988,
        employee: 'Jasmine Phoebe',
        saleDate: new Date()
      },
      {
        product: `ตู้เย็น 2 ประตู (14.1 คิว, สี Elega`,
        price: 11290,
        qty: 1,
        total: 11290,
        employee: 'Jasmine Phoebe',
        saleDate: new Date()
      },
      {
        product: `เครื่องซักผ้าฝาหน้า (7 kg)`,
        price: 10689,
        qty: 1,
        total: 10689,
        employee: 'Jasmine Phoebe',
        saleDate: new Date()
      },
      {
        product: `ทีวี TU8300 UHD LED (65", 4K,`,
        price: 23900,
        qty: 2,
        total: 47800,
        employee: 'Jasmine Phoebe',
        saleDate: new Date()
      },
      {
        product: `SAMSUNG ทีวี UHD LEDปี 2021`,
        price: 18900,
        qty: 3,
        total: 56700,
        employee: 'Jasmine Phoebe',
        saleDate: new Date()
      }
    ],
    salesList: [
      {
        id: 1,
        product: 'ทีวี UHD LED ปี 2021 (50",4K,Smart) รุ่น UA50AU7700KXXT',
        price: 14988,
        qty: 35,
        total: 524580
      },
      {
        id: 2,
        product: 'ตู้เย็น 2 ประตู (14.1 คิว, สี Elega)',
        price: 11290,
        qty: 23,
        total: 259670
      },

      {
        id: 3,
        product: 'เครื่องซักผ้าฝาหน้า (7 kg)',
        price: 10689,
        qty: 12,
        total: 128268
      },

      {
        id: 4,
        product: 'ทีวี TU8300 UHD LED (65", 4K)',
        price: 23900,
        qty: 5,
        total: 119500
      },

      {
        id: 5,
        product: 'SAMSUNG ทีวี UHD LEDปี 2021',
        price: 18900,
        qty: 10,
        total: 180900
      }
    ]
  }
}

export const WorkReport = {
  success: true,
  data: {
    checkIn: 23,
    leaveWork: 26,
    absentWork: 2,
    report: [
      {
        name: 'สมคิด มีความสุข',
        date: new Date(),
        startWork: new Date(),
        offWork: new Date(),
        timeWork: '08:55:00',
        status: 'ปกติ'
      },
      {
        name: 'อาชา สมปรารถณา',
        date: new Date(),
        startWork: '',
        offWork: '',
        timeWork: '00:00:00',
        status: 'ขาด'
      },
      {
        name: 'มณีจันทร์ จันทรา',
        date: new Date(),
        startWork: '',
        offWork: '',
        timeWork: '00:00:00',
        status: 'ลาป่วย'
      },
      {
        name: 'ธรรมรัฐ สมบูรณ์ดี',
        date: new Date(),
        startWork: '',
        offWork: '',
        timeWork: '00:00:00',
        status: 'ลาพักร้อน'
      },
      {
        name: 'อรณิสา มีวาสนา',
        date: new Date(),
        startWork: '',
        offWork: '',
        timeWork: '00:00:00',
        status: 'ลากิจ'
      }
    ]
  }
}

export const stockReport = {
  success: true,
  data: {
    reportStock: [
      {
        product: `ทีวี UHD LED ปี 2021 (50",4K,Smart) รุ่น UA50AU7700KXXT`,
        category: 'ทีวี',
        stock: 23,
        show: 2,
        lastDate: new Date()
      },
      {
        product: `ตู้เย็น 2 ประตู (14.1 คิว, สี Elega`,
        category: 'ตู้เย็น',
        stock: 34,
        show: 3,
        lastDate: new Date()
      },
      {
        product: `เครื่องซักผ้าฝาหน้า (7 kg)`,
        category: 'เครื่องซักผ้า',
        stock: 55,
        show: 2,
        lastDate: new Date()
      },
      {
        product: `ทีวี TU8300 UHD LED (65", 4K,`,
        category: 'ทีวี',
        stock: 12,
        show: 2,
        lastDate: new Date()
      },
      {
        product: `SAMSUNG ทีวี UHD LEDปี 2021`,
        category: 'ทีวี',
        stock: 78,
        show: 2,
        lastDate: new Date()
      }
    ],
    stockList: [
      {
        id: 1,
        lastDate: new Date(),
        employee: 'Jasmine Phoebe'
      },
      {
        id: 2,
        lastDate: new Date(),
        employee: 'Aysha Madeleine'
      },
      {
        id: 3,
        lastDate: new Date(),
        employee: 'Christina Melissa'
      },
      {
        id: 4,
        lastDate: new Date(),
        employee: 'Marie Arnold'
      },
      {
        id: 5,
        lastDate: new Date(),
        employee: 'Tamara Mcfarlane'
      }
    ]
  }
}

export const stockReportById = {
  success: true,
  data: {
    date: new Date(),
    name: 'ทีวี UHD LED ปี 2021 (50",4K,Smart) รุ่น UA50AU7700KXXT',
    result: [
      {
        saleDate: new Date(),
        employee: 'Jasmine Phoebe',
        price: 14988,
        qty: 35,
        total: 524580
      },
      {
        saleDate: new Date(),
        employee: 'Aysha Madeleine',
        price: 11290,
        qty: 23,
        total: 259670
      },
      {
        saleDate: new Date(),
        employee: 'Christina Melissa',
        price: 10689,
        qty: 12,
        total: 128268
      },
      {
        saleDate: new Date(),
        employee: 'Marie Arnold',
        price: 23900,
        qty: 5,
        total: 119500
      },
      {
        saleDate: new Date(),
        employee: 'Tamara Mcfarlane',
        price: 18900,
        qty: 10,
        total: 180900
      }
    ]
  }
}

export const rivalReport = {
  success: true,
  data: [
    {
      category: 'ทีวี',
      total: 134789
    },
    { category: 'ตู้เย็น', total: 134789 },
    { category: 'เครื่องซักผ้า', total: 134789 }
  ]
}
