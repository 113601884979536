import { useEffect, useState, createContext, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Role } from '@constants'
import { AbilityContext } from '@src/utility/context/Can'
import {
  SUPER_ADMIN_Ability,
  ADMIN_Ability,
  BRAND_MANEGER_Ability,
} from '@configs/acl/initialAbility'
import { brandService } from '@src/api'

const Authention = createContext()

const AuthentionContext = ({ children }) => {
  const navigate = useNavigate()
  const ability = useContext(AbilityContext)

  function setLocalStorage(key, value) {
    try {
      window.localStorage.setItem(key, JSON.stringify(value))
    } catch (e) {
      console.error(e)
    }
  }

  function getLocalStorage(key, initialValue) {
    try {
      const value = window.localStorage.getItem(key)
      return value ? JSON.parse(value) : initialValue
    } catch (e) {
      return initialValue
    }
  }

  function removeLocalStorage(key, value) {
    try {
      window.localStorage.removeItem(key, JSON.stringify(value))
    } catch (e) {
      console.error(e)
    }
  }

  const [user, setUser] = useState(() => getLocalStorage('user', null))
  const [brand, setBrand] = useState(() => getLocalStorage('brand', null))
  const [selectedBrand, setSelectedBrand] = useState(() =>
    getLocalStorage('selectedBrand', null),
  )
  const [selectedBranch, setSelectedBranch] = useState(() =>
    getLocalStorage('selectedBranch', null),
  )

  const removeStorage = () => {
    removeLocalStorage('user', null)
    removeLocalStorage('brand', null)
    removeLocalStorage('selectedBrand', null)
    removeLocalStorage('selectedBranch', null)
    localStorage.clear()
  }
  const logout = () => {
    setUser(null)
    setBrand(null)
    setSelectedBrand(null)
    setSelectedBranch(null)
    removeStorage()

    if (user?.role && [Role.ADMIN, Role.SUPER_ADMIN].includes(user.role.id)) {
      navigate('/login/admin')
    } else {
      navigate('/login/brand-keeper')
    }
  }

  const fetchBrand = async (brandId) => {
    const brandResult = await brandService.getBrandById(brandId)
    const _brandId = brandResult.data.data.item.id
    const branchResult = await brandService.getBranchByBrand(_brandId, {
      limit: 0,
    })
    let brandData = {
      brand: brandResult.data.data.item,
      brandId: _brandId,
      branchId:
        branchResult.data.data.items.length > 0
          ? branchResult.data.data.items[0].id
          : null,
      branchs: branchResult.data.data.items,
    }
    setBrand(brandData)
  }

  useEffect(() => {
    if (user) {
      setLocalStorage('user', user)
      if (user?.role?.id) {
        switch (user.role.id) {
          case Role.ADMIN:
            ability.update(ADMIN_Ability)
            break
          case Role.SUPER_ADMIN:
            ability.update(SUPER_ADMIN_Ability)
            break
          default:
            ability.update(BRAND_MANEGER_Ability)
            break
        }
      }
    }
  }, [user])

  useEffect(async () => {
    if (brand?.brandId) {
      await fetchBrand(brand.brandId)
    }
  }, [])

  useEffect(() => {
    if (brand) {
      setLocalStorage('brand', brand)
    }
  }, [brand])

  useEffect(() => {
    if (selectedBrand) {
      setLocalStorage('selectedBrand', selectedBrand)
    }
  }, [selectedBrand])

  useEffect(() => {
    if (selectedBranch) {
      setLocalStorage('selectedBranch', selectedBranch)
    }
  }, [selectedBranch])

  return (
    <Authention.Provider
      value={{
        user,
        brand,
        selectedBrand,
        selectedBranch,
        setBrand: (value) => setBrand(value),
        setUser: (value) => setUser(value),
        setSelectedBrand: (value) => setSelectedBrand(value),
        setSelectedBranch: (value) => setSelectedBranch(value),
        fetchBrand,
        logout: () => logout(),
      }}
    >
      {children}
    </Authention.Provider>
  )
}

export const useAuth = () => useContext(Authention)

export { Authention, AuthentionContext }
