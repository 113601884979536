// ** Initial user ability
export const initialAbility = []

export const SUPER_ADMIN_Ability = [
  {
    action: 'manage',
    subject: 'all',
  },
]

export const ADMIN_Ability = [
  {
    action: 'read',
    resource: 'select-brand',
  },
  {
    action: 'read',
    subject: 'dashboard-overall',
  },
  {
    action: 'read',
    subject: 'dashboard-work-report',
  },
  {
    action: 'read',
    subject: 'dashboard-stock-report',
  },
  {
    action: 'read',
    subject: 'dashboard-rival-report',
  },
  {
    action: 'read',
    resource: 'dashboard-export-report',
  },
  {
    action: 'read',
    subject: 'dashboard-sale-report',
  },
  {
    action: 'manage',
    subject: 'human-resources',
  },
  {
    action: 'manage',
    subject: 'stock',
  },
  {
    action: 'manage',
    subject: 'employee',
  },
  {
    action: 'manage',
    subject: 'manage-stock',
  },
  {
    action: 'manage',
    subject: 'account-brand',
  },
]

export const BRAND_MANEGER_Ability = [
  {
    action: 'read',
    subject: 'dashboard-overall',
  },
  {
    action: 'read',
    subject: 'dashboard-sale-report',
  },
  {
    action: 'read',
    subject: 'dashboard-work-report',
  },
  {
    action: 'read',
    subject: 'dashboard-stock-report',
  },
  {
    action: 'read',
    subject: 'dashboard-rival-report',
  },
  {
    action: 'read',
    resource: 'dashboard-export-report',
  },
  {
    action: 'manage',
    subject: 'stock',
  },
  {
    action: 'manage',
    subject: 'employee',
  },
]
